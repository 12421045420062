import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'top',
    component: () => import('../views/CorpTop.vue'),
    meta: { title: 'ii-anbai TOP' }
  },
  {
    path: '/top',
    name: 'top',
    component: () => import('../views/CorpTop.vue'),
    meta: { title: 'ii-anbai TOP' }
  },
  {
    path: '/Overview',
    name: 'Overview',
    component: () => import('../views/CorpOverview.vue'),
    meta: { title: 'ii-anbai Overview' }
  }

]

const router = new VueRouter({
  routes
})

export default router
