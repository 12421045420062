<template>
  <v-container>
    <v-row class="text-end">
      <v-spacer></v-spacer>
      <v-col cols="2" md="1" lg="1">
        <div class="itemTitleMenu01" @click="Click_Top('/')">
          Top
        </div>
      </v-col>
      <v-col cols="3" md="2" lg="1">
        <div class="itemTitleMenu01" @click="Click_Top('#/Overview')">
          会社概要
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.itemTitleMenu01
{
  text-align: right;
  font-size: 0.9em;
}

</style>

<script>
export default {
  methods: {
    Click_Top(pUrl) {
      window.location.href = pUrl;
    },
  }

}
</script>