<template>

  <v-app>
    <v-app-bar app color="#FFFFFF" flat >
      <TopMenuView />
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer  >
      <v-row justify="center" no-gutters>
      <v-col class="text-center mt-4" cols="12">
        <div style="font-size: 0.8em;">Copyright © 2023 ii-anbai LLC. All rights reserved.</div>
      </v-col>
    </v-row>
    </v-footer>
  </v-app>
</template>

<script>

import TopMenuView from '@/components/TopMenuView.vue'

export default {
  name: 'App',

  components: {
    TopMenuView
  },

  methods: {
    setMeta(route) {
      // タイトルを設定
      if (route.meta.title) {
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if (route.meta.desc) {
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    }
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    '$route'(route) {
      this.setMeta(route);
    }
  }

};
</script>
